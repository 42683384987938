import { Controller } from 'stimulus'
// import Flickity from 'flickity'
import Flickity from 'flickity-fade'

export default class extends Controller {
    static targets = ['carousel']  

    initialize() {
      this.flkty = new Flickity( this.carouselTarget, {
        cellAlign: 'left',
        setGallerySize: false,
        pageDots: false,
        prevNextButtons: false,
        autoPlay: 3000,
        pauseAutoPlayOnHover: false,
        fade: true,
        adaptiveHeight: true,
        lazyLoad: 2,
        wrapAround: true,
        selectedAttraction: 0.01,   
      })
    }

    connect () {
    }

    disconnect () {
      this.flkty.destroy()
    }

  }