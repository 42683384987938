import { Controller } from 'stimulus'


export default class extends Controller {

    initialize() {
    }

    connect () {

        let options = {
            root: null,
            rootMargin: "-300px -300px -300px -300px",
            threshold: 0
        }
        const logo = document.querySelector('.home-logo')

        logo.classList.add('js-hidden')
        
        this.observer = new IntersectionObserver((entries) => {
            const ratio = entries[0].intersectionRatio;

            if (logo) {

                if (ratio !==0 ) {
                    logo.classList.remove('js-hidden')
                }

                if (ratio === 0) {
                    logo.classList.add('js-hidden')
                }
            }
        }, options);
        
        const element = document.querySelector('.triger-logo-front')
        this.observer.observe(element);
    }

    disconnect () {
    }

  }