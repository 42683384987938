import '../css/app.scss'

require('intersection-observer')

import lazysizes from 'lazysizes' 

import * as Turbo from "@hotwired/turbo"

import { Application } from "stimulus"

import HeroController from "./controllers/hero_controller"
import MenuController from "./controllers/menu_controller"
import MainmenuController from "./controllers/mainmenu_controller"
import LogoController from "./controllers/logo_controller"
import LogoFooterController from "./controllers/logoFooter_controller"
import LogoFrontController from "./controllers/logoFront_controller"


const application = Application.start()
application.register('hero', HeroController)
application.register('menu', MenuController)
application.register('mainmenu', MainmenuController)
application.register('logo', LogoController)
application.register('logoFooter', LogoFooterController)
application.register('logoFront', LogoFrontController)

document.addEventListener("turbo:load", function(event) {
    console.log(event.detail.url.substr(event.detail.url.indexOf('/', 7) + 1))
    let url = event.detail.url.substr(event.detail.url.indexOf('/', 7) + 1) ? event.detail.url.substr(event.detail.url.indexOf('/', 7) + 1) : 'PLB LAB'
    console.log(url)
    if (typeof gtag === 'function') {
        gtag('config', 'G-5X1L92FDRR', {
          'page_location': url,
          'page_path':url,
          'page_title':url
        });
      }


    // strange scrolling behavior fix
    if (!window.location.hash) {
        window.scrollTo(0, 0);
      }



    if (event.detail.timing.visitStart) {
        let animation = document.querySelector('.hide-animation')
        if (animation) {
            animation.remove() 
        }
    } else {
        let carousel = document.querySelector('.herocarousel')
        if (carousel) {
            carousel.classList.add('hero')
        }
        let text = document.querySelector('.fronttext')
        if (text) {
            text.classList.add('cover-animation')
        }
        let logo = document.querySelector('.white-logo')
        if (logo) {
            text.classList.add('hide-animation')
        }
    }
  })