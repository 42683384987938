import { Controller } from 'stimulus'


export default class extends Controller {

    initialize() {
    }

    connect () {

        let options = {
            root: null,
            rootMargin: "0px 0px 0px 0px",
            threshold: 0
        }

        document.querySelector('.menu').classList.remove('js-menu')
        
        this.observer = new IntersectionObserver((entries) => {
            const ratio = entries[0].intersectionRatio;

            if (ratio === 0) {
                document.querySelector('.menu').classList.add('js-menu')
            }

            if (ratio !== 0) {
                document.querySelector('.menu').classList.remove('js-menu') 
            }
        }, options);
        
        const element = document.querySelector('.trigger')
        this.observer.observe(element);
    }

    disconnect () {
    }

  }